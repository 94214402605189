import React, { Component } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link, graphql } from 'gatsby'
import slugify from 'slugify'

slugify.extend({ ä: 'ae' })
slugify.extend({ ö: 'oe' })
slugify.extend({ ü: 'ue' })
slugify.extend({ Ä: 'AE' })
slugify.extend({ Ö: 'OE' })
slugify.extend({ Ü: 'UE' })
slugify.extend({ ',': '.' })

import { WeinPageButton, DarkWeinPageButton } from '../components/styles/Button'

import arrow from '../images/arrow.svg'
import navbarArrow from '../images/navbar-arrow.svg'

import { formatPrice } from '../utilities/formatPrice'
import StyledLink from '../components/styles/StyledLink'
import SEO from '../components/SEO'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  /* margin-bottom: 90px; */
  margin-bottom: 50px;
  /* margin-top: 90px; */
  /* margin-top: 169px; */
  margin-top: 158px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`

const Left = styled.div`
  /* position: relative; */
  /* width: 54%; */
  width: 60%;
  align-self: flex-end;
  position: sticky;
  /* top: 100px; */
  bottom: 20px;
  padding-right: 80px;
  /* margin-bottom: auto; */
  /* FOR NON-GRID-SUPPORT */
  position: relative;
  @supports (display: grid) {
    position: sticky;
  }
  @media (max-width: 1000px) {
    position: relative;
    bottom: 0;
    width: 100%;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }
`

const Right = styled.div`
  /* width: 46%; */
  width: 40%;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const WineImage = styled.img`
  max-width: 600px;
  @media (max-width: 1000px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

const BlankContainer = styled.div`
  width: 100%;
  /* padding-top: 133.333333%; */
  max-width: 600px;
  @media (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
  }
`
const Blank = styled.div`
  width: 100%;
  padding-top: 133.333333%;
  max-width: 600px;
`

const WineImageContainer = styled.div`
  position: relative;
`

const CircleImage = styled(GatsbyImage)`
  /* top: 0; */
  width: 100%;
  max-width: 600px;
`

const CircleContainer = styled.div`
  position: absolute;
  /* top: 121px; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
  }
`

const AwardContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 65%;
  width: 130px;
  z-index: -1;
  img {
    max-width: 100%;
  }
  @media (max-width: 1200px) {
    left: 66%;
    width: 120px;
  }
  @media (max-width: 1000px) {
    left: 70%;
    width: 130px;
  }
  @media (max-width: 900px) {
    left: 73%;
  }
  @media (max-width: 700px) {
    left: 75%;
    width: 125px;
  }
  @media (max-width: 600px) {
    /* left: 78%; */
    width: 115px;
    bottom: 50px;
  }
  @media (max-width: 520px) {
    /* left: 76%; */
    width: 105px;
    bottom: 50px;
  }
  @media (max-width: 450px) {
    /* left: 76%; */
    width: 95px;
    bottom: 30px;
  }
  @media (max-width: 420px) {
    /* left: 76%; */
    width: 85px;
    bottom: 30px;
  }
  /* @media (max-width: 600px) {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 170px;
  } */
  /* @media (max-width: 400px) {
    width: 110px;
  } */
`

const AwardImg = styled.img`
  margin-top: 26px;
  /* @media (max-width: 600px) {
    width: 100%;
    max-width: 200px;
  } */
`

const Back = styled.span`
  font-weight: 300;
`

const Heading = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  /* margin-top: 40px; */
  /* margin-top: 10px; */
`

const HeadingLeft = styled.h1`
  font-family: Montserrat, sans-serif;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 2px;
  color: ${p => p.theme.textColorDark};
  line-height: 1.3;
  padding-top: 4px;
  padding-bottom: 4px;
  @media (max-width: 420px) {
    font-size: 35px;
    letter-spacing: 1.7px;
  }
  /* overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto; */
`

// const HeadingRight = styled.h2`
//   color: ${p => p.theme.textColorDark};
//   font-size: 21px;
//   font-weight: 300;
//   padding-left: 24px;
//   padding-bottom: 7px;
// `

const SubHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: 300;
  padding-bottom: 40px;
  border-bottom: 1px solid ${p => p.theme.lightGray};
`

const SubHeadingLeft = styled.h2`
  font-size: 24px;
  font-weight: 300;
  padding-right: 10px;
`

const SubHeadingRight = styled.h2`
  font-size: 24px;
  font-weight: 300;
  padding-right: 10px;
`

const SubHeadingDivider = styled.div`
  padding-right: 10px;
  font-size: 22px;
`

const PriceContainer = styled.div`
  display: flex;
  margin-top: 40px;
`

const Price = styled.h2`
  color: ${p => p.theme.textColorDark};
  font-size: 26px;
  font-weight: 600;
  flex: 0 0 auto;
`

const PriceInfo = styled.div`
  align-self: center;
  padding-left: 24px;
  font-weight: 300;
  margin-top: 4px;
  @media (max-width: 350px) {
    font-size: 15px;
  }
`

const Description = styled.div`
  margin-top: 40px;
  /* margin-bottom: 44px; */
  margin-bottom: 68px;
  * {
    width: 100%;
  }
  p {
    margin-bottom: 26px !important;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  p span {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  h1 {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    color: #787878 !important;
    letter-spacing: 2px !important;
  }
  h1 span {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    color: #787878 !important;
    letter-spacing: 2px !important;
  }
  h2 {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    color: #787878 !important;
    letter-spacing: 2px !important;
  }
  h2 span {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    color: #787878 !important;
    letter-spacing: 2px !important;
    @media (max-width: 400px) {
      font-size: 10px !important;
      letter-spacing: 1.7px !important;
    }
  }
  position: relative;
  h2 {
    width: 33%;
    border-top: 1px solid ${p => p.theme.lightGray};
    border-right: 1px solid ${p => p.theme.lightGray};
    border-left: 1px solid ${p => p.theme.lightGray};
    padding-top: 10px;
    text-align: center;
  }
  h2 + p {
    width: 33%;
    position: absolute;
    bottom: -60px;
    left: 0px;
    border-right: 1px solid ${p => p.theme.lightGray};
    border-bottom: 1px solid ${p => p.theme.lightGray};
    border-left: 1px solid ${p => p.theme.lightGray};
    padding-bottom: 10px;
    text-align: center;
  }
  h2 + p span {
    font-size: 14px !important;
  }
  h2 + p + h2 + p {
    left: 33%;
  }
  h2 + p + h2 + p + h2 + p {
    left: 66%;
  }
  h2 + p + h2 {
    border-right: 1px solid ${p => p.theme.lightGray};
    border-left: none;
  }
  h2 + p + h2 + p {
    border-right: 1px solid ${p => p.theme.lightGray};
    border-left: none;
  }
`

const SubButton = styled.div`
  display: flex;
  color: ${p => p.theme.textColorDark};
  font-weight: 300;
  margin-top: 14px;
  flex-wrap: wrap;
  line-height: 1.8;
`

const DualContainer = styled.div`
  display: flex;
  margin-bottom: 26px;
  align-items: center;
`

const TextLeft = styled.h2`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 1.2px;
  min-width: 140px;
`

const TextRight = styled.div`
  display: flex;
`

const AnzahlOperatorPlus = styled.span`
  /* margin: auto; */
  line-height: 1;
  color: ${p => p.theme.textColor};
`

const AnzahlOperatorMinus = styled.span`
  /* margin: auto; */
  line-height: 1;
  padding-bottom: 3px;
  color: ${p => p.theme.textColor};
`

const AnzahlButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 32px;
  border: 1px solid ${p => p.theme.midGray};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.midGray};
  font-size: 20px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: 0.12s ease-out;
  user-select: none;
  :hover {
    background-color: ${p => p.theme.textColorDark};
    border-color: ${p => p.theme.textColorDark};
  }
  :hover ${AnzahlOperatorPlus}, :hover ${AnzahlOperatorMinus} {
    color: white;
  }
  margin-top: -3px;
`

const Anzahl = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  line-height: 1.4;
`

const HeadingWhenSmall = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  margin-top: -30px;
  @media (max-width: 1000px) {
    display: flex;
  }
`

const HeadingWhenBig = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    display: none;
  }
`

const Separator = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`

const Arrow = styled.img`
  transform: rotate(180deg);
  width: 15px;
  margin-bottom: 3px;
  margin-right: 8px;
`

const NextWineArrowImage = styled.img`
  width: 22px;
  transition: 0.2s ease-out;
`

const NextWineArrow = styled.div`
  position: fixed;
  right: 3%;
  top: 50%;
  transform: rotate(-90deg);
  @media (max-width: 1320px) and (min-width: 1001px) {
    right: 1%;
  }
  @media (max-width: 1000px) {
    position: absolute;
  }
  :hover ${NextWineArrowImage} {
    transform: translateY(3px);
  }
`
const PreviousWineArrow = styled.div`
  position: fixed;
  left: 3%;
  top: 50%;
  transform: rotate(90deg);
  @media (max-width: 1320px) and (min-width: 1001px) {
    left: 1%;
  }
  @media (max-width: 1000px) {
    position: absolute;
  }
  :hover ${NextWineArrowImage} {
    transform: translateY(3px);
  }
`

const HoverDark = styled.span`
  :hover {
    color: black;
  }
`

const maxQuantity = 99
const minQuantity = 1

class Wein extends Component {
  state = {
    quantity: 1,
  }
  handleIncrementQuantity = () => {
    this.state.quantity >= maxQuantity
      ? null
      : this.setState(prevState => {
          return { quantity: prevState.quantity + 1 }
        })
  }
  handleDecrementQuantity = () => {
    this.state.quantity <= minQuantity
      ? null
      : this.setState(prevState => {
          return { quantity: prevState.quantity - 1 }
        })
  }

  handleBuy = () => {
    const { addVariantToCart } = this.props
    const { product } = this.props.data
    const shopifyId = product.variants[0].shopifyId
    addVariantToCart(shopifyId, this.state.quantity, this.props.data.checkoutNumber.siteMetadata.checkoutNumber)
  }

  render() {
    const { slugNext, slugPrevious } = this.props.pageContext
    const { circleImage, product, collection } = this.props.data
    const { cartIsLoading } = this.props

    let isForSale = false
    if (product?.variants?.[0]?.availableForSale) {
      isForSale = true
    }

    let title = ''
    if (product && product.title) {
      title = product.title
    }

    let shopifyWineImage = undefined
    if (
      product.media &&
      product.media[0] &&
      product.media[0].preview &&
      product.media[0].preview.image &&
      product.media[0].preview.image.originalSrc
    ) {
      shopifyWineImage = product.media[0].preview.image.originalSrc
    }

    let awardsArray = undefined
    let awardImages = undefined
    if (product.media && product.media.length > 1) {
      awardsArray = product.media.slice(1)
      awardImages = awardsArray.map(image => {
        if (image?.preview?.image?.originalSrc) {
          return image?.preview?.image?.originalSrc
        } else {
          return false
        }
      })
    }

    let year = '-'
    let taste = undefined
    let volumen = '0,75 L'
    if (product.options) {
      let filteredYear = product.options.filter(option => option.name === 'Jahrgang')
      let filteredClassification = product.options.filter(option => option.name === 'Klassifizierung')
      let filteredVolumen = product.options.filter(option => option.name === 'Volumen')
      if (filteredYear && filteredYear[0] && filteredYear[0].values && filteredYear[0].values[0]) {
        year = filteredYear[0].values[0]
      }
      if (
        filteredClassification &&
        filteredClassification[0] &&
        filteredClassification[0].values &&
        filteredClassification[0].values[0]
      ) {
        taste = filteredClassification[0].values[0]
      }
      if (filteredVolumen && filteredVolumen[0] && filteredVolumen[0].values && filteredVolumen[0].values[0]) {
        volumen = filteredVolumen[0].values[0].replace(/\./, ',')
      }
    }
    let description = ''
    if (product.descriptionHtml) {
      description = product.descriptionHtml
    }
    const descriptionPlain = product.description
    // let variantId = ''
    // if (product && product.variants && product.variants[0] && product.variants[0].id) {
    //   variantId = product.variants[0].id
    // }
    let collectionTitle = undefined
    if (collection && collection.title) {
      collectionTitle = collection.title
    }
    return (
      <React.Fragment>
        <SEO title={`${title} | ${year} | ${taste} | ${volumen}`} description={descriptionPlain} />
        <Container>
          <HeadingWhenSmall>
            {collectionTitle && (
              <Link style={{ alignSelf: 'flex-start' }} to={`/Weine/#${slugify(collectionTitle).toUpperCase()}`}>
                <Back>
                  <Arrow src={arrow} alt="" />
                  Zurück
                </Back>
              </Link>
            )}
            <Heading>
              <HeadingLeft>{title}</HeadingLeft>
            </Heading>
            <SubHeading>
              <SubHeadingLeft>{year}</SubHeadingLeft>
              {taste && (
                <React.Fragment>
                  <SubHeadingDivider>|</SubHeadingDivider>
                  <SubHeadingRight>{taste}</SubHeadingRight>
                </React.Fragment>
              )}
              <SubHeadingDivider>|</SubHeadingDivider>
              <SubHeadingRight>{volumen}</SubHeadingRight>
            </SubHeading>
          </HeadingWhenSmall>
          <Left>
            <WineImageContainer>
              {shopifyWineImage ? (
                <WineImage alt={title} critical={true} src={shopifyWineImage} />
              ) : (
                <BlankContainer>
                  <Blank />
                </BlankContainer>
              )}
              <PreviousWineArrow>
                <Link to={`/${slugPrevious}`}>
                  <NextWineArrowImage src={navbarArrow} alt="" />
                </Link>
              </PreviousWineArrow>
              <NextWineArrow>
                <Link to={`/${slugNext}`}>
                  <NextWineArrowImage src={navbarArrow} alt="" />
                </Link>
              </NextWineArrow>
            </WineImageContainer>
            <CircleContainer>
              {collectionTitle !== 'PACKAGES' && !title?.toLowerCase()?.includes('package') && (
                <CircleImage alt="" critical={true} image={circleImage.childImageSharp.gatsbyImageData} />
              )}
            </CircleContainer>
            <AwardContainer>
              {awardImages &&
                awardImages.length > 0 &&
                awardImages.map(image => {
                  return <AwardImg key={image.id} src={image} />
                })}
            </AwardContainer>
          </Left>
          <Right>
            <HeadingWhenBig>
              {collectionTitle && (
                <Link style={{ alignSelf: 'flex-start' }} to={`/Weine/#${slugify(collectionTitle).toUpperCase()}`}>
                  <Back>
                    <Arrow src={arrow} alt="" />
                    Zurück
                  </Back>
                </Link>
              )}
              <Heading>
                <HeadingLeft>{title}</HeadingLeft>
              </Heading>
              <SubHeading>
                <SubHeadingLeft>{year}</SubHeadingLeft>
                {taste && (
                  <React.Fragment>
                    <SubHeadingDivider>|</SubHeadingDivider>
                    <SubHeadingRight>{taste}</SubHeadingRight>
                  </React.Fragment>
                )}
                <SubHeadingDivider>|</SubHeadingDivider>
                <SubHeadingRight>{volumen}</SubHeadingRight>
              </SubHeading>
            </HeadingWhenBig>
            <PriceContainer>
              <Price>{formatPrice(product.variants[0].price)}</Price>
              <PriceInfo>inkl. USt. zzgl. Versand</PriceInfo>
            </PriceContainer>
            <Description>
              <div style={{ display: 'flex', flexWrap: 'wrap' }} dangerouslySetInnerHTML={{ __html: description }} />
            </Description>
            {isForSale ? (
              <React.Fragment>
                <DualContainer>
                  <TextLeft>ANZAHL</TextLeft>
                  <TextRight>
                    <AnzahlButton onClick={this.handleDecrementQuantity}>
                      <AnzahlOperatorMinus>&ndash;</AnzahlOperatorMinus>
                    </AnzahlButton>
                    <Anzahl>{this.state.quantity}</Anzahl>
                    <AnzahlButton onClick={this.handleIncrementQuantity}>
                      <AnzahlOperatorPlus>+</AnzahlOperatorPlus>
                    </AnzahlButton>
                  </TextRight>
                </DualContainer>
                <DualContainer>
                  <TextLeft>LIEFERZEIT</TextLeft>
                  <TextRight>3-5 Werktage in Österreich</TextRight>
                </DualContainer>
                <WeinPageButton disabled={cartIsLoading} onClick={this.handleBuy}>
                  IN DEN WARENKORB
                </WeinPageButton>
                <SubButton>
                  <Link to="/AGB/">
                    <HoverDark>Liefer- und Zahlungsbedingungen</HoverDark>
                  </Link>{' '}
                  <Separator>|</Separator>{' '}
                  <Link to="/Versand/">
                    <HoverDark>Versandkosten</HoverDark>
                  </Link>
                </SubButton>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <DarkWeinPageButton as="a" href="mailto:office@weingut-goeschl.at" dark>
                  AUF ANFRAGE
                </DarkWeinPageButton>
                <div style={{ marginTop: '30px' }}>
                  Wir freuen uns auf Ihre Bestellung an{' '}
                  <StyledLink as="a" style={{ whiteSpace: 'nowrap' }} href="mailto:office@weingut-goeschl.at">
                    office@weingut-goeschl.at
                  </StyledLink>
                </div>
              </React.Fragment>
            )}
          </Right>
        </Container>
      </React.Fragment>
    )
  }
}

export default Wein

export const query = graphql`
  query ($id: String!) {
    circleImage: file(relativePath: { eq: "photos/half-circle.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    product: myStoreShopifyProduct(id: { eq: $id }) {
      id
      title
      description
      descriptionHtml
      options {
        name
        values
      }
      variants {
        price
        id
        shopifyId
        availableForSale
      }
      media {
        preview {
          image {
            originalSrc
          }
        }
      }
    }
    collection: myStoreShopifyCollection(products: { elemMatch: { id: { eq: $id } } }) {
      title
    }
    checkoutNumber: site {
      siteMetadata {
        checkoutNumber
      }
    }
  }
`
